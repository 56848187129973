import 'dotenv/config';

export const config = {
  cognitoClientId: async () => process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID!,
  // cognitoClientId: async () => await userSessionRepo.getCognitoClientId(),

  // This looks weird, but using a function prevents Next.js from pre-rendering the value.
  // The window object doesn't exist at compile time.
  appUrl: () => window.location.origin,
  // appUrl: process.env.NEXT_PUBLIC_APP_URL ?? 'http://localhost:3000',

  cognitoDomain: process.env.NEXT_PUBLIC_COGNITO_DOMAIN!,

  apiUrl: () => process.env.NEXT_PUBLIC_API_URL!,

  region: () => process.env.NEXT_PUBLIC_AWS_REGION!,
};
